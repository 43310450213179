import React, { useEffect, useState, useContext } from "react";
import axiosBaseURL from "../../../httpCommon";
import { DrtContext } from "../../../context/DrtContext";
import Loader from "../../Loader";

const MinisterComponent = () => {
  const { EncryptDecrypt } = useContext(DrtContext);
  const [featureImage, setFeatureImage] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const getFeatureImage = async () => {
    try {
      const result = await axiosBaseURL.get("/get-feature-member");
      const temp = EncryptDecrypt(result.data);
      const res = temp.data.slice(0, 3);
      setFeatureImage(res);
      setIsLoading(false);
    } catch (error) {
      setError("An error occurred while fetching the data.");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getFeatureImage();
  }, []);

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <div className="row">
        {featureImage ? (
          featureImage.map((item, index) => (
            <div
              key={index + 1}
              className="col-12 col-sm-12 col-md-4 col-lg-6 bdr-right text-center min-wrap"
            >
              <div className="director-section">
                <img src={item.image_url} className="img-fluid" alt="minister" />
                <h4>{item.name}</h4>
                <p>Hon’ble {item.designation}</p>
              </div>
            </div>
          ))
        ) : (
          <p>No data Found</p>
        )}
      </div>
    </>
  );
};

export default MinisterComponent;
