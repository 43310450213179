import React from "react";
import Captcha from "react-numeric-captcha";
import { useState, useEffect, useRef } from "react";
import { Modal, Table, Button } from "react-bootstrap";
import { useFormik } from "formik";
import { years , generateYearList } from "../../../../../services/YearService";
import { axiosBaseURLExternal } from "../../../../../httpCommon";

const CaseNumberDrat = () => {
  const [loading, setLoading] = useState(false);

  const [captchaStatus, setCaptchaStatus] = useState(false);
  const capt = useRef();

  const [dratLocationName, setDratLocationName] = useState("");

  // state to control Model
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenModal2, setIsOpenModal2] = useState(false);

  const [dratLocations, setDratLocations] = useState([]);
  const [caseTypeDrt, setCaseTypeDrt] = useState([]);

  // fetching first dropDownData
  const fetchDratLocation = async () => {
    const res = await axiosBaseURLExternal.post("getDrtDratScheamName");

    const sortedLocation = res.data.sort((a, b) =>
      a.SchemaName > b.SchemaName ? 1 : b.SchemaName > a.SchemaName ? -1 : 0
    );
    setDratLocations(sortedLocation);
  };

  useEffect(() => {
    fetchDratLocation();
  }, []);

  // fetching second dropDownData
  const fetchCaseType = async (value) => {
    const data = {
      schemeNameDrtId: value,
    };
    const res = await axiosBaseURLExternal.post("getDrtDratCaseTyepName", data);
    setCaseTypeDrt(res.data);
  };

  // get final data after sending required payload
  const [caseDetail, setCaseDetail] = useState([]);
  const [error, setError] = useState(false);
  const fetchCaseDetail = async (url, data) => {
    try {
      setLoading(true);

      const response = await axiosBaseURLExternal.post(url, data);
      setCaseDetail(response.data);
    } catch (error) {
      setCaseDetail([]);
      setError(true);
    }
    setLoading(false);
  };

  const initialValues = {
    schemeNameDratDrtId: "",
    casetypeId: "",
    caseNo: "",
    caseYear: new Date().getFullYear(),
  };

  const validate = (values) => {
    let errors = {};

    if (!values.schemeNameDratDrtId) {
      errors.schemeNameDratDrtId = "Required";
    }
    if (!values.casetypeId) {
      errors.casetypeId = "Required";
    }
    if (!values.caseNo.trim()) {
      errors.caseNo = "Required";
    } else if (values.caseNo.trim().length > 8) {
      errors.caseNo = "Maximum length is 8 characters";
    } else if (!/^[0-9]+$/.test(values.caseNo.trim())) {
      errors.caseNo = "Only numbers are allowed";
    }
    if (!values.caseYear) {
      errors.caseYear = "Required";
    }
    return errors;
  };

  const getDrtDratName = (id) => {
    let name = dratLocations.filter((item) => item.schemeNameDrtId === id);
    setDratLocationName(name[0]?.SchemaName);
  };

  const onSubmit = (values, { resetForm }) => {
    const caseDetailData = {
      schemeNameDrtId: values.schemeNameDratDrtId,
      casetype: values.casetypeId,
      caseNo: values.caseNo,
      caseYear: values.caseYear,
    };

    getDrtDratName(values.schemeNameDratDrtId);

    fetchCaseDetail("getDratCaseDetailCaseNoWise", caseDetailData);
    setTimeout(() => {
      setIsOpenModal(true);
    }, 1000);

    setCaptchaStatus(false);
    // resetForm();
    capt.current.refresh();
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validate,
  });

  const doubleDigitDate = (date) => {
    // let date = "25/8/2022"
    if (date) {
      let arr = date.split("/");
      if (arr[0].length === 1) arr[0] = "0" + arr[0];
      if (arr[1].length === 1) arr[1] = "0" + arr[1];

      return arr.join("/");
    }
  };

  return (
    <div className="col-12 col-sm-12 col-md-12 col-lg-12 about-content">
      <form onSubmit={formik.handleSubmit}>
        <div className="form-row">
          <div className="form-group col-md-4">
            <label className="mandatory" htmlFor="staticEmail">
              DRAT
            </label>

            <select
              className="form-control"
              placeholder=""
              type="text"
              id="schemeNameDratDrtId"
              name="schemeNameDratDrtId"
              onChange={(e) => {
                formik.handleChange(e);
                fetchCaseType(e.target.value);
              }}
              value={formik.values.schemeNameDratDrtId}
              onBlur={formik.handleBlur}
            >
              <option>Select DRAT Location</option>
              {dratLocations &&
                dratLocations
                  .filter((item) => parseInt(item.schemeNameDrtId) >= 100)
                  .map((item, index) => (
                    <option value={`${item.schemeNameDrtId}`} key={index}>
                      {item.SchemaName}
                    </option>
                  ))}
            </select>
            {formik.touched.schemeNameDratDrtId &&
            formik.errors.schemeNameDratDrtId ? (
              <div className="error">{formik.errors.schemeNameDratDrtId}</div>
            ) : null}
          </div>

          <div className="form-group col-md-4">
            <label className="mandatory" htmlFor="">
              Case Type
            </label>

            <select
              id="casetypeId"
              name="casetypeId"
              type="select"
              className="form-control"
              placeholder=""
              onChange={formik.handleChange}
              value={formik.values.casetypeId}
              onBlur={formik.handleBlur}
            >
              <option>Select Case Type</option>
              {caseTypeDrt.map((item, index) => (
                <option value={`${item.caseType}`} key={index}>
                  {item.caseTypeName}
                </option>
              ))}
            </select>
            {formik.touched.casetypeId && formik.errors.casetypeId ? (
              <div className="error">{formik.errors.casetypeId}</div>
            ) : null}
          </div>

          <div className="form-group col-md-4">
            <label className="mandatory" htmlFor="">
              Case No
            </label>

            <input
              id="caseNo"
              name="caseNo"
              type="text"
              className="form-control"
              placeholder="Enter Case No"
              onChange={formik.handleChange}
              value={formik.values.caseNo}
              onBlur={formik.handleBlur}
              autoComplete="off"
            />
            {formik.touched.caseNo && formik.errors.caseNo ? (
              <div className="error">{formik.errors.caseNo}</div>
            ) : null}
          </div>

          <div className="form-group col-md-4">
            <label className="mandatory" htmlFor="">
              Case Year
            </label>

            <select
              type="text"
              className="form-control"
              placeholder=""
              id="caseYear"
              name="caseYear"
              onChange={formik.handleChange}
              value={formik.values.caseYear}
              onBlur={formik.handleBlur}
            >
              {generateYearList().map((item, index) => (
                <option value={item} key={index}>
                  {item}
                </option>
              ))}
            </select>
            {formik.touched.caseYear && formik.errors.caseYear ? (
              <div className="error">{formik.errors.caseYear}</div>
            ) : null}
          </div>

          <div className="form-group col-md-8 main-menu-captcha capcha-sec">
            <label>Captcha</label>
            <div className="col-sm-12 px-0 d-flex">
              <Captcha
                onChange={(status) => {
                  setCaptchaStatus(status);
                }}
                ref={capt}
                required
              />
              <br />
              <button
                type="submit"
                disabled={!captchaStatus}
                className="btn btn-reload"
              >
                <i className="fas fa-search"><span className="sr-only">no text</span></i>
              </button>
            </div>
            {!captchaStatus ? (
              <div className="captcha-text">Enter valid captcha</div>
            ) : null}
          </div>
        </div>
      </form>
      {loading ? (
        <h6 className="loader">Loading ......</h6>
      ) : (
        <Modal show={isOpenModal} onHide={() => setIsOpenModal(false)}>
          <Modal.Header>
            DRT/DRAT Case Status Report <br /> {dratLocationName}
            <Button
              type="button"
              className="close"
              onClick={() => setIsOpenModal(false)}
            >
              &times;
            </Button>
          </Modal.Header>
          <Modal.Body>
            <div className="table-responsive mb-3">
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Applicant V/S Respondent</th>
                    <th>Dairy No.</th>
                    <th>Case Type</th>
                    <th>Case No.</th>
                    <th>Date of Filing</th>
                    <th>View More</th>
                  </tr>
                </thead>
                {!error && caseDetail ? (
                  <tbody>
                    <tr>
                      <td>
                        {caseDetail?.petitionerName} <br />
                        V/S <br />
                        {caseDetail?.respondentName}
                      </td>
                      <td>
                        {caseDetail?.diaryno}/{caseDetail?.diaryyear}
                      </td>
                      <td>{caseDetail?.casetype}</td>
                      <td>
                        {caseDetail?.casetype}/{caseDetail?.caseno}/
                        {caseDetail?.caseyear}
                      </td>
                      <td>{doubleDigitDate(caseDetail?.dateoffiling)}</td>
                      <td>
                        <button
                          className="pop-btn btn"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setIsOpenModal2(true);
                            // setIsOpenModal(false);
                          }}
                        >
                          More Detail
                        </button>
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <>
                    <tbody>
                      <tr>
                        <td colSpan="6"> No Record Found </td>
                      </tr>
                    </tbody>
                  </>
                )}
              </Table>
            </div>
          </Modal.Body>
        </Modal>
      )}
      <Modal show={isOpenModal2} onHide={() => setIsOpenModal2(false)}>
        <Modal.Header>
          <div>
            <b> More Details </b>
            <br />
            <span>{dratLocationName}</span>
          </div>
          <Button
            type="button"
            className="close"
            onClick={() => setIsOpenModal2(false)}
          >
            &times;
          </Button>
        </Modal.Header>
        <Modal.Body>
          {/* new case detail */}
          {caseDetail && caseDetail.othercase?.length > 0 && (
            <div className="table-responsive mb-3">
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th colSpan="2">CASE DETAIL</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Main Diary No</td>
                    <td>
                      <strong>
                        {caseDetail && caseDetail?.othercase[0]?.maincasedairy}
                      </strong>
                    </td>
                  </tr>
                  <tr>
                    <td>Main Case No</td>
                    <td>
                      <strong>
                        {caseDetail && caseDetail?.othercase[0]?.maincaseno}
                      </strong>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          )}

          {/* case status */}
          <div className="table-responsive mb-3">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th colSpan="2">CASE STATUS</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Diary No/Year</td>
                  <td>
                    <strong>
                      {caseDetail?.diaryno}/{caseDetail?.diaryyear}
                    </strong>
                  </td>
                </tr>
                <tr>
                  <td>Case Type/Case No/Year</td>
                  <td>
                    <strong>
                      {caseDetail?.casetype}/{caseDetail?.caseno}/
                      {caseDetail?.caseyear}
                    </strong>
                  </td>
                </tr>
                <tr>
                  <td>Date of Filing.</td>
                  <td>
                    <strong>{doubleDigitDate(caseDetail?.dateoffiling)}</strong>
                  </td>
                </tr>
                <tr>
                  <td>Case Status.</td>
                  <td>
                    <strong>{caseDetail?.casestatus}</strong>
                  </td>
                </tr>
                <tr>
                        <td>Date of Disposal</td>
                        <td>
                          <strong>{caseDetail?.dateofdisposal}</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>Nature of Disposal</td>
                        <td>
                          <strong>{caseDetail?.disposalNature}</strong>
                        </td>
                      </tr>
              </tbody>
            </Table>
          </div>

          {/* case listing details Tentative */}
          {caseDetail?.courtName && caseDetail?.courtNo && (
            <div className="table-responsive mb-3">
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th colSpan="2">
                      <strong>CASE LISTING DETAILS</strong>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>In the Court of</td>
                    <td>
                      <strong>{caseDetail.courtName}</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>Court No.</td>
                    <td>
                      <strong>{caseDetail.courtNo}</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>Next Listing Date:</td>
                    <td>
                      <strong>
                        {doubleDigitDate(caseDetail.nextlistingdate)}
                      </strong>
                    </td>
                  </tr>
                  <tr>
                    <td>Next Listing Purpose:</td>
                    <td>
                      <strong>{caseDetail.nextListingPurpose}</strong>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          )}

          {/* petitioner's details */}
          <div className="table-responsive mb-3">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th colSpan="2">
                    <strong>PETITIONER/APPLICANT DETAILS</strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Petitioner Name:</td>
                  <td>
                    <strong>{caseDetail?.petitionerName}</strong>
                  </td>
                </tr>
                <tr>
                  <td>Petitioner/Applicant Address:</td>
                  <td>
                    <strong>{caseDetail?.petitionerApplicantAddress}</strong>
                  </td>
                </tr>
                <tr>
                  <td>Additional Party:</td>
                  <td>
                    <strong>{caseDetail?.additionalpartypet}</strong>
                  </td>
                </tr>
                <tr>
                  <td>Advocate Name:</td>
                  <td>
                    <strong>{caseDetail?.advocatePetName}</strong>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>

          {/* respondent's details */}
          <div className="table-responsive mb-3">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th colSpan="2">
                    <strong>RESPONDENTS/DEFENDANT DETAILS</strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Respondent Name:</td>
                  <td>
                    <strong>{caseDetail?.respondentName}</strong>
                  </td>
                </tr>
                <tr>
                  <td>Respondent/Defendant Address:</td>
                  <td>
                    <strong>{caseDetail?.respondentDefendentAddress}</strong>
                  </td>
                </tr>
                <tr>
                  <td>Additional Party:</td>
                  <td>
                    <strong>{caseDetail?.additionalpartyres}</strong>
                  </td>
                </tr>
                <tr>
                  <td>Advocate Name:</td>
                  <td>
                    <strong>{caseDetail?.advocateResName}</strong>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>

          {/* case proceeding details */}
          {caseDetail?.caseProceedingDetails?.length > 0 && (
            <div className="table-responsive mb-3">
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th colSpan="4">CASE PROCEEDING DETAILS</th>
                  </tr>
                  <tr>
                    <th>Court Name</th>
                    <th>Cause List Date</th>
                    <th>Purpose</th>
                    <th>Order</th>
                  </tr>
                </thead>
                <tbody>
                  {caseDetail?.caseProceedingDetails?.map((item, index) => (
                    <tr key={index}>
                      <td>{item.ascourtName}</td>
                      <td>{doubleDigitDate(item?.causelistdate)}</td>
                      <td>{item.purpose}</td>
                      <td>
                        {item.orderUrl && (
                          <a target="_blank" href={`${item.orderUrl}`}>
                            <i className="fas fa-search"><span className="sr-only">no text</span></i>
                          </a>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          )}

          {/* ia details */}
          {caseDetail?.iaDetails?.length > 0 && (
            <div className="table-responsive mb-3">
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th colSpan="6">IA DETAILS</th>
                  </tr>
                  <tr>
                    <th>Sr. No.</th>
                    <th>IA Case No.</th>
                    <th>Date of Filling</th>
                    <th>Date of Order</th>
                    <th>View IA</th>
                    <th>View IA Order</th>
                  </tr>
                </thead>
                <tbody>
                  {caseDetail?.iaDetails?.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.iano}</td>
                      <td>{doubleDigitDate(item.iadateoffiling)}</td>
                      <td>{doubleDigitDate(item.iaorderdate)}</td>
                      <td>
                        {item.iaUrl && (
                          <a
                            target="_blank"
                            href={`https://drt.gov.in/drtlive/order/pdf/pdfia.php?file=${item.iaUrl}`}
                          >
                            <i className="fas fa-search"><span className="sr-only">no text</span></i>
                          </a>
                        )}
                      </td>
                      <td>
                        {item.orderUrl && (
                          <a target="_blank" href={`${item?.orderUrl}`}>
                            <i className="fas fa-search"><span className="sr-only">no text</span></i>
                          </a>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          )}

          {/* RC Details */}
          {caseDetail?.rcdetail && (
            <>
              <div className="table-responsive mb-3">
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th colSpan="4">
                        {" "}
                        RC DETAILS{" "}
                        <strong>
                          {" "}
                          &nbsp;&nbsp;(
                          {caseDetail?.rcdetail[0]?.diaryno})
                        </strong>
                      </th>
                    </tr>
                    <tr>
                      <th>Court Name</th>
                      <th>RC List Date</th>
                      <th>Purpose</th>
                      <th>RC Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{caseDetail.rcdetail[0]?.courtname}</td>
                      <td>
                        {doubleDigitDate(
                          caseDetail.rcdetail[0]?.nextlistingdate
                        )}
                      </td>
                      <td>{caseDetail.rcdetail[0]?.nextListingPurpose}</td>
                      <td>{caseDetail.rcdetail[0]?.status}</td>
                    </tr>
                  </tbody>
                </Table>
              </div>

              {/* // RC Proceeding details */}
              {caseDetail?.rcdetail[0]?.rcCaseProceedingDetails.length > 0 && (
                <div className="table-responsive mb-3">
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th colSpan="3">RC CASE PROCEEDING DETAILS</th>
                      </tr>
                      <tr>
                        <th>Court No</th>
                        <th>Cause List Date</th>
                        <th>Purpose</th>
                      </tr>
                    </thead>
                    <tbody>
                      {caseDetail.rcdetail[0]?.rcCaseProceedingDetails?.map(
                        (item, index) => (
                          <tr key={index}>
                            <td>{item.courtNo}</td>
                            <td>{doubleDigitDate(item.causelistdate)}</td>
                            <td>{item.purpose}</td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </Table>
                </div>
              )}
            </>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default CaseNumberDrat;
